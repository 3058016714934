import React from 'react';
import { Helmet } from 'react-helmet';
import { Content } from "../components/body/Body";

import { Footer } from '../components/Footer';
import { TransitionNavigation } from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>FastApps Security</title>
    </Helmet>
    <Wrapper>
      <TransitionNavigation mode="normal" />
      <Content>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader className="post-full-header" style={{ paddingBottom: 0 }}>
            <PostFullTitle className="post-full-title">
              FastApps Security
            </PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                At FastApps security of our customers data is our number one priority. We go to great lengths to prevent logging, storing, or saving users data.
              </p>

              <h4>Does FastApps store my data?</h4>
              <p>
                No, FastApps does not store any data returned from your API endpoints or DB queries. FastApps only acts as a proxy layer. Hence, as a user, when you query your database/API endpoint, the FastApps server only appends sensitive credentials before forwarding the request to your backend. The FastApps server doesn't expose sensitive credentials to the browser because that can lead to security breaches. Such a routing ensures security of your systems and data.
              </p>

              <h4>Security measures within FastApps</h4>
              <p>
                At FastApps, we believe in building applications that are secure-by-default. Security measures implemented for FastApps installations are:
                
                <ul>
                  <li>On FastApps Cloud, all connections are encrypted with TLS. For self-hosted instances, we offer the capability to setup SSL certificates via LetsEncrypt during the installation process.</li>
                  <li>Encrypt all sensitive credentials such as database credentials with AES-256 encryption. Each self-hosted FastApps instance (coming soon) will be configured with unique salt and password values ensuring data-at-rest security.</li>
                  <li>FastApps Cloud will only connect to your databases/API endpoints through whitelisted IP: 104.131.34.162. This ensures that you only have to expose database access to specific IPs when using our cloud offering.</li>
                  <li>FastApps Cloud is hosted in DigitalOcean data centers on servers that are SOC 1 and SOC 2 compliant. We also maintain data redundancy on our cloud instances via regular backups.</li>
                  <li>Internal access to FastApps Cloud is controlled through 2-factor authentication system along with audit logs.</li>
                  <li>Maintain an open channel of communication with security researchers to allow them to report security vulnerabilities responsibly. If you notice a security vulnerability, please email security@fastapps.io and we'll resolve them ASAP.</li>
                </ul>
              </p>

            </div>
          </PostFullContent>
        </article>
      </Content>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
